import { DispatchPreview } from "@api/graphql/generated/generated-types";
import { DispatchCandidateSortingType } from "../types";

export function sortDispatchPreviews(
  dispatchPreviews: DispatchPreview[],
  sortingType?: DispatchCandidateSortingType
): DispatchPreview[] {
  if (sortingType === undefined) {
    return dispatchPreviews;
  }

  return [...dispatchPreviews].sort((a, b) => {
    const compared = compare(a, b, sortingType);
    if (compared === 0) {
      return a.driver.id.localeCompare(b.driver.id);
    }
    return compared;
  });
}

function compare(
  a: DispatchPreview,
  b: DispatchPreview,
  sortingType: DispatchCandidateSortingType
): number {
  switch (sortingType) {
    case DispatchCandidateSortingType.CLOSEST:
      if (
        a.distanceInMeters === null ||
        a.distanceInMeters === undefined ||
        b.distanceInMeters === null ||
        b.distanceInMeters === undefined
      ) {
        if (a.distanceInMeters) {
          // It means a has distance but b doesn't
          // So show a first
          return -1;
        }
        if (b.distanceInMeters) {
          // It means b has duration but a doesn't
          // So show b first
          return 1;
        }
        return defaultCompare(a, b);
      }
      const distanceCompare = a.distanceInMeters - b.distanceInMeters;
      if (distanceCompare !== 0) {
        return distanceCompare;
      }
      return defaultCompare(a, b);
    case DispatchCandidateSortingType.LEAST_DEVIATION:
      if (
        a.distanceAdditionalInMeters === null ||
        a.distanceAdditionalInMeters === undefined ||
        b.distanceAdditionalInMeters === null ||
        b.distanceAdditionalInMeters === undefined
      ) {
        if (a.distanceAdditionalInMeters) {
          // It means a has distanceAdditionalInMeters but b doesn't
          // So show a first
          return -1;
        }
        if (b.distanceAdditionalInMeters) {
          // It means b has distanceAdditionalInMeters but a doesn't
          // So show b first
          return 1;
        }
        return defaultCompare(a, b);
      }
      const distanceAdditionalCompare =
        a.distanceAdditionalInMeters - b.distanceAdditionalInMeters;
      if (distanceAdditionalCompare !== 0) {
        return distanceAdditionalCompare;
      }
      return defaultCompare(a, b);
    case DispatchCandidateSortingType.LEAST_TASKS:
      if (
        a.numCurrentTasks === null ||
        a.numCurrentTasks === undefined ||
        b.numCurrentTasks === null ||
        b.numCurrentTasks === undefined
      ) {
        return defaultCompare(a, b);
      }
      const numCurrentTasksCompare = a.numCurrentTasks - b.numCurrentTasks;
      if (numCurrentTasksCompare !== 0) {
        return numCurrentTasksCompare;
      }
      return defaultCompare(a, b);
    case DispatchCandidateSortingType.LEAST_WORKLOAD:
      if (
        a.remainingWorkloadInSeconds === null ||
        a.remainingWorkloadInSeconds === undefined ||
        b.remainingWorkloadInSeconds === null ||
        b.remainingWorkloadInSeconds === undefined
      ) {
        return defaultCompare(a, b);
      }
      const workloadCompare =
        a.remainingWorkloadInSeconds - b.remainingWorkloadInSeconds;
      if (workloadCompare !== 0) {
        return workloadCompare;
      }
      return defaultCompare(a, b);
    default:
      return defaultCompare(a, b);
  }
}

function defaultCompare(a: DispatchPreview, b: DispatchPreview): number {
  if (
    a.distanceInMeters !== null &&
    a.distanceInMeters !== undefined &&
    b.distanceInMeters !== null &&
    b.distanceInMeters !== undefined
  ) {
    const distanceCompare = a.distanceInMeters - b.distanceInMeters;
    if (distanceCompare !== 0) {
      return distanceCompare;
    }
  }
  if (
    a.durationInSeconds !== null &&
    a.durationInSeconds !== undefined &&
    b.durationInSeconds !== null &&
    b.durationInSeconds !== undefined
  ) {
    const durationCompare = a.durationInSeconds - b.durationInSeconds;
    if (durationCompare !== 0) {
      return durationCompare;
    }
  }
  if (
    a.distanceAdditionalInMeters !== null &&
    a.distanceAdditionalInMeters !== undefined &&
    b.distanceAdditionalInMeters !== null &&
    b.distanceAdditionalInMeters !== undefined
  ) {
    const distanceAdditionalCompare =
      a.distanceAdditionalInMeters - b.distanceAdditionalInMeters;
    if (distanceAdditionalCompare !== 0) {
      return distanceAdditionalCompare;
    }
  }
  if (
    a.remainingWorkloadInSeconds !== null &&
    a.remainingWorkloadInSeconds !== undefined &&
    b.remainingWorkloadInSeconds !== null &&
    b.remainingWorkloadInSeconds !== undefined
  ) {
    const remainingWorkloadInSecondsCompare =
      a.remainingWorkloadInSeconds - b.remainingWorkloadInSeconds;
    if (remainingWorkloadInSecondsCompare !== 0) {
      return remainingWorkloadInSecondsCompare;
    }
  }
  if (
    a.numCurrentTasks !== null &&
    a.numCurrentTasks !== undefined &&
    b.numCurrentTasks !== null &&
    b.numCurrentTasks !== undefined
  ) {
    const numCurrentTasksCompare = a.numCurrentTasks - b.numCurrentTasks;
    if (numCurrentTasksCompare !== 0) {
      return numCurrentTasksCompare;
    }
  }

  return 0;
}
