import { TransportationType } from "@api/graphql/generated/generated-types";

export function displayVehicleType(transportationType?: TransportationType) {
  if (transportationType === undefined) {
    return "";
  }
  switch (transportationType) {
    case TransportationType.Car:
      return "Car";
    case TransportationType.Truck:
      return "Truck";
    case TransportationType.Van:
      return "Van";
    case TransportationType.Bike:
      return "Bike";
    case TransportationType.Walking:
      return "Walk";
    case TransportationType.PublicTransportation:
      return "Public Transportation";
    case TransportationType.Other:
      return "Other";
    default:
      return "Unknown";
  }
}
