import { Label, Select } from "@src/common/components";
import React from "react";

type Props = {
  setSelectedDuration: (duration: string) => void;
  selectedDuration: string;
  onChange?: () => void;
};

function DurationTypeSelector({
  setSelectedDuration,
  selectedDuration,
  onChange,
}: Props) {
  const recurrenceScheduleOptions = [
    { label: "No", id: "noStopDates" },
    { label: "Yes", id: "hasStopDates" },
  ];

  const handleChange = (params) => {
    setSelectedDuration(params.value[0]?.id);
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className="flex flex-col">
      <Label
        htmlFor={"Service Type"}
        className="block text-xs font-medium text-gray-700 truncate"
      >
        Multiple Days
      </Label>
      <Select
        options={recurrenceScheduleOptions}
        value={recurrenceScheduleOptions.filter(
          (option) => option.id === selectedDuration
        )}
        size="mini"
        clearable={false}
        onChange={handleChange}
        searchable={false}
      />
    </div>
  );
}

export default DurationTypeSelector;
