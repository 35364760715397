import { DispatchPreviewTag } from "@api/graphql/generated/generated-types";

export function displayDispatchPreviewTag(
  tag: DispatchPreviewTag
): string | undefined {
  switch (tag) {
    case DispatchPreviewTag.Closest:
      return "Closest";
    case DispatchPreviewTag.LeastDeviation:
      return "Least Deviation";
    case DispatchPreviewTag.LeastTasks:
      return "Least Tasks";
    case DispatchPreviewTag.LeastWorkload:
      return "Least Workload";
    case DispatchPreviewTag.FastestDelivery:
      return "Fastest Delivery";
    case DispatchPreviewTag.NoTask:
      return "No Task";
    default:
      return undefined;
  }
}
