const MapErrorFallback = ({ error }: { error: Error }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full p-4 bg-gray-100">
      <div className="max-w-md w-full text-center border   p-4 rounded-md shadow-md">
        <h2 className="text-lg font-semibold text-red-600">
          Something went wrong
        </h2>
        <p className="mt-2 text-gray-700">
          We encountered an error while loading the map:
        </p>
        <pre className="mt-2 text-sm text-red-500 whitespace-pre-wrap">
          {error.message}
        </pre>
        <button
          className="mt-4 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          onClick={() => window.location.reload()}
        >
          Reload Page
        </button>
      </div>
    </div>
  );
};

export default MapErrorFallback;
