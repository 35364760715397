import { StopTimeStatus } from "@api/graphql/generated/generated-types";

import {
  CheckIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";

export function displayStopTimeStatus(
  stopTimeStatus: StopTimeStatus | undefined,
  isPickup: boolean
) {
  switch (stopTimeStatus) {
    case StopTimeStatus.Completed:
      if (isPickup) {
        return "Picked Up";
      } else {
        return "Delivered";
      }
    case StopTimeStatus.Late:
      return "Late";
    case StopTimeStatus.LateSoon:
      return "Late Soon";
    case StopTimeStatus.LikelyLate:
      return "Likely Late";
    case StopTimeStatus.NotReady:
      return "Not Ready";
    case StopTimeStatus.OnTime:
      if (isPickup) {
        return "Ready";
      }
      return "On Time";
    default:
      return "";
  }
}

export function chooseIconBasedOnStopTimeStatus(
  stopTimeStatus: StopTimeStatus | undefined
) {
  switch (stopTimeStatus) {
    case StopTimeStatus.Completed:
      return CheckIcon;
    case StopTimeStatus.Late:
      return ExclamationCircleIcon;
    case StopTimeStatus.LateSoon:
      return ClockIcon;
    case StopTimeStatus.LikelyLate:
      return ClockIcon;
    case StopTimeStatus.NotReady:
      return ClockIcon;
    case StopTimeStatus.OnTime:
      return CheckIcon;
    default:
      return undefined;
  }
}

export const adjustTimeWindow = (timeWindow: any, shipmentDate: string) => {
  const adjustDate = (timestamp: string | null, shipmentDate: string) => {
    if (timestamp === null) return null;

    const time = moment.unix(parseInt(timestamp));
    const date = moment(shipmentDate);

    // Set year, month, and day of shipmentDate keeping the time same
    date.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: time.get("second"),
    });

    return date.unix().toString();
  };

  return {
    open: adjustDate(timeWindow.open, shipmentDate),
    close: adjustDate(timeWindow.close, shipmentDate),
  };
};
