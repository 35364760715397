export const mapGLMapStyles = {
  light: {
    url: "mapbox://styles/tugicourie/clxku7ykz00bv01po6w9z9p3z",
  },
  dark: {
    url: "mapbox://styles/tugicourie/clxkufnrd00b001o74vecastg",
  },
  noTrafficLight: {
    url: "mapbox://styles/tugicourie/cm0wvtwz400zu01pwdh948i71",
  },
  noTrafficDark: {
    url: "mapbox://styles/tugicourie/cm1a0n9b205cl01pq7isxaiff",
  },
};
