import { LngLat, Maybe } from "@api/graphql/generated/generated-types";

export function getBoundsFromLocations(
  coordinates: LngLat[]
): [[number, number], [number, number]] | null {
  const validLocations = coordinates.filter(
    (coordinate) =>
      coordinate.lng !== undefined &&
      coordinate.lng !== null &&
      coordinate.lat !== undefined &&
      coordinate.lat !== null
  );

  if (validLocations.length === 0) {
    return null;
  }

  let minLng = validLocations[0].lng as number;
  let maxLng = validLocations[0].lng as number;
  let minLat = validLocations[0].lat as number;
  let maxLat = validLocations[0].lat as number;

  for (const stop of validLocations) {
    minLng = Math.min(stop.lng as number, minLng);
    maxLng = Math.max(stop.lng as number, maxLng);
    minLat = Math.min(stop.lat as number, minLat);
    maxLat = Math.max(stop.lat as number, maxLat);
  }

  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
}

export function getBoundsFromLocationsNoLngLat(
  coordinates: [number, number][]
): [[number, number], [number, number]] | null {
  if (coordinates.length === 0) {
    return null;
  }

  let minLng = coordinates[0][0];
  let maxLng = coordinates[0][0];
  let minLat = coordinates[0][1];
  let maxLat = coordinates[0][1];

  for (const coord of coordinates) {
    minLng = Math.min(coord[0], minLng);
    maxLng = Math.max(coord[0], maxLng);
    minLat = Math.min(coord[1], minLat);
    maxLat = Math.max(coord[1], maxLat);
  }

  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
}

export function getValidCoordinate(
  coord: Maybe<number> | undefined,
  defaultValue: number
): number {
  return coord !== undefined && coord !== null && !isNaN(coord)
    ? coord
    : defaultValue;
}

export function isValidLngLat(lngLat: LngLat): boolean {
  return (
    !!lngLat && // This ensures lngLat is neither null nor undefined
    typeof lngLat.lng === "number" &&
    !isNaN(lngLat.lng) &&
    typeof lngLat.lat === "number" &&
    !isNaN(lngLat.lat)
  );
}
