/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import MapGL, {
  FullscreenControl,
  Layer,
  Marker,
  Source,
  Viewport,
} from "@urbica/react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Driver,
  GetDispatchPreviewsQueryVariables,
  LngLat,
  Shipment,
  ShipmentStatus,
  ShipmentStopType,
  Stop,
  useGetDispatchPreviewsLazyQuery,
} from "@api/graphql/generated/generated-types";
import { BADGE_ENHANCER_SIZES, FixedMarker } from "baseui/map-marker";
import { formatTitleCaseWithUnderscores } from "@src/common/lib/textUtils";
import {
  BadgeMeta,
  makeStopDisplayStatusBadge,
} from "@src/shipments/utils/stopBadgeFormatters";
import { useCourieStore } from "@src/common/lib/store";
import Button from "@src/common/components/Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  getBoundsFromLocations,
  getValidCoordinate,
  isValidLngLat,
} from "@src/common/lib/mapBoundCalculator";
import { displayStopTimeStatus } from "@src/shipments/utils/stopTimeStatusDisplayer";
import { Loading } from "../Loading";
import { DriverAvatar } from "../Avatar";
import axios from "axios";
import { useRouter } from "next/router";
import { makeDriverMapItems } from "@src/shipments/utils/makeDispatchCandidateViews";
import { mapGLMapStyles } from "@src/common/constants/mapStyles";
import { ErrorBoundary } from "react-error-boundary";
import MapErrorFallback from "../MapErrorFallback/MapErrorFallback";

const NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

export type DriverMapWidgetProps = {
  shipment: Shipment | null | undefined;
  drivers?: DriverMapItem[];
  defaultBoundaryIncludeDrivers?: boolean | false;
  showDriverDetail?: boolean;
  showBadge?: boolean | false;
  showDispatchPreviews?: boolean;
  selectedDriver?: Driver | undefined;
  setSelectedDriver?: React.Dispatch<React.SetStateAction<Driver | undefined>>;
  isAssignDriverModalOpen?: boolean;
  setIsAssignDriverModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DriverMapItem = {
  id: string;
  title: string;
  image: string;
  subText: string;
  coordinate?: LngLat;
  isActive?: boolean;
};

const DriverMapWidget = ({
  shipment,
  showBadge = true,
  defaultBoundaryIncludeDrivers = false,
  showDriverDetail = false,
  showDispatchPreviews = false,
  selectedDriver,
  setSelectedDriver,
  isAssignDriverModalOpen,
  setIsAssignDriverModalOpen,
}: DriverMapWidgetProps) => {
  // graphql
  const [
    getDispatchPreviews,
    {
      data: dispatchPreviewApiData,
      loading: dispatchPreviewLoading,
      error: dispatchPreviewError,
      startPolling: dispatchPreviewStartPolling,
      stopPolling: dispatchPreviewStopPolling,
    },
  ] = useGetDispatchPreviewsLazyQuery();
  const { pathname } = useRouter();
  const myDivRef = useRef<HTMLDivElement>(null);
  const selectedShipmentsMemo = useMemo(() => shipment, [shipment]);
  const [driverDetail, setDriverDetail] = useState<DriverMapItem | null>(null);
  const [drivers, setDrivers] = useState<DriverMapItem[]>([]);
  const [height, setHeight] = useState(window.innerHeight - 50);
  const mapRef: React.RefObject<MapGL> = useRef(null);
  const [viewport, setViewport] = useState<Viewport | null>({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 3,
  });
  const { setSelectedWidgetItem } = useCourieStore();
  const [routeData, setRouteData] = useState(null);

  const fetchRouteData = async (stopsCoordinates: LngLat[]) => {
    const coordinates = stopsCoordinates
      .map((stop) => `${stop.lng},${stop.lat}`)
      .join(";");
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?overview=full&geometries=geojson&access_token=${process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}`;
    const response = await axios.get(url);
    const route = response.data.routes[0].geometry;
    setRouteData(route);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  // Function to check if the map is in fullscreen
  const checkFullscreen = () => {
    if (mapRef.current) {
      const fullscreenElement = document.fullscreenElement;
      setIsFullscreen(
        fullscreenElement === mapRef.current.getMap().getContainer()
      );
    }
  };

  useEffect(() => {
    if (!setSelectedDriver) {
      return;
    }
    if (driverDetail) {
      const selectedDriver = dispatchPreviewApiData?.dispatchPreviews.find(
        (dispatchPreview) => dispatchPreview.driver?.id === driverDetail.id
      )?.driver;
      if (selectedDriver) {
        setSelectedDriver(selectedDriver as Driver);
      }
    } else {
      setSelectedDriver(undefined);
    }
  }, [driverDetail]);

  useEffect(() => {
    setDriverDetail(null);
    if (selectedShipmentsMemo && selectedShipmentsMemo.driver) {
      const driver = selectedShipmentsMemo.driver;
      setDrivers([
        {
          id: driver.id || "",
          title: driver.firstName || "",
          subText: "",
          image: driver.photoUrl || "",
          coordinate: driver.lastReportedLocation?.lngLat,
          isActive: driver.isActive,
        },
      ]);
      return;
    }
    if (
      selectedShipmentsMemo &&
      selectedShipmentsMemo.status === ShipmentStatus.Created &&
      showDispatchPreviews
    ) {
      getDispatchPreviews({
        variables: {
          shipmentId: selectedShipmentsMemo.id,
          taskStatusFilter: ["CREATED", "STARTED"],
          shipmentDate: selectedShipmentsMemo.shipmentDate,
        } as GetDispatchPreviewsQueryVariables,
        fetchPolicy: "cache-and-network",
      }).then((res) => {
        const drivers = makeDriverMapItems(res.data);
        setDrivers(drivers);
      });
    }
  }, [selectedShipmentsMemo]);

  useEffect(() => {
    setRouteData(null);
  }, [pathname]);

  useEffect(() => {
    if (myDivRef.current) {
      const height = myDivRef.current.offsetHeight;
      setHeight(height);
    }
    document.addEventListener("fullscreenchange", checkFullscreen);

    return () => {
      document.removeEventListener("fullscreenchange", checkFullscreen);
    };
  }, []);

  useEffect(() => {
    if (mapRef.current && selectedShipmentsMemo) {
      const map = mapRef.current.getMap();
      const stopsCoordinates: LngLat[] = selectedShipmentsMemo.stops
        .map((stop: Stop) => stop.lngLat)
        .filter(isValidLngLat);
      if (stopsCoordinates.length > 1) {
        fetchRouteData(stopsCoordinates);
      }
      let driverCoordinates: LngLat[] = [];
      if (selectedShipmentsMemo.driver) {
        const driver = selectedShipmentsMemo.driver;
        driverCoordinates = [driver.lastReportedLocation as LngLat];
      }
      if (drivers) {
        driverCoordinates = drivers
          .map((driver: DriverMapItem) => driver.coordinate)
          .filter(
            (coordinate: LngLat | undefined) => coordinate !== undefined
          ) as LngLat[];
      }
      const bounds = getBoundsFromLocations(
        defaultBoundaryIncludeDrivers
          ? [...stopsCoordinates, ...driverCoordinates]
          : stopsCoordinates
      );
      if (bounds) {
        map.fitBounds(bounds, {
          padding: { left: 80, right: 100, top: 50, bottom: 50 },
          maxZoom: 16,
          pitch: 30,
        });
      }
    } else {
      setDriverDetail(null);
    }
  }, [selectedShipmentsMemo]);

  const toggleFullscreen = () => {
    if (mapRef?.current) {
      const mapContainer = mapRef.current.getMap().getContainer();

      if (!document.fullscreenElement) {
        if (mapContainer.requestFullscreen) {
          mapContainer.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  };

  return (
    <Loading loading={dispatchPreviewLoading} className="h-full">
      <ErrorBoundary FallbackComponent={MapErrorFallback}>
        <div className="h-full" ref={myDivRef}>
          <MapGL
            ref={mapRef}
            style={{
              width: "100%",
              height: height,
              borderTopLeftRadius: "20px",
            }}
            mapStyle={mapGLMapStyles.light.url}
            accessToken={NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
            latitude={viewport?.latitude || 0}
            longitude={viewport?.longitude || 0}
            zoom={viewport?.zoom || 0}
            onViewportChange={setViewport}
          >
            <>
              {selectedShipmentsMemo?.stops.map((stop: Stop, i) => {
                const badge: BadgeMeta | undefined = showBadge
                  ? makeStopDisplayStatusBadge(
                      stop.timeStatus || undefined,
                      stop.timeWindow,
                      stop.type === ShipmentStopType.PickUp
                    )
                  : undefined;
                return (
                  <Marker
                    key={i}
                    longitude={getValidCoordinate(stop.lngLat.lng, 0)}
                    latitude={getValidCoordinate(stop.lngLat.lat, 0)}
                  >
                    <FixedMarker
                      label={formatTitleCaseWithUnderscores(stop.type)}
                      badgeEnhancerSize={BADGE_ENHANCER_SIZES.mediumText}
                      badgeEnhancerContent={() =>
                        showBadge ? (
                          <span className="text-xs truncate">
                            {stop.timeStatus &&
                              displayStopTimeStatus(
                                stop.timeStatus,
                                stop.type === ShipmentStopType.PickUp
                              )}
                          </span>
                        ) : null
                      }
                      overrides={{
                        BadgeEnhancer: {
                          style: {
                            backgroundColor: badge?.backgroundColor,
                          },
                        },
                      }}
                    />
                  </Marker>
                );
              })}
              {drivers?.map((driver: DriverMapItem, i: number) => {
                return (
                  <Marker
                    key={i}
                    longitude={getValidCoordinate(driver.coordinate?.lng, 0)}
                    latitude={getValidCoordinate(driver.coordinate?.lat, 0)}
                  >
                    <DriverAvatar
                      onClick={() => setDriverDetail(driver)}
                      driverName={driver.title}
                      driverPhoto={driver.image}
                      isActive={driver.isActive}
                      className="cursor-pointer"
                    />
                  </Marker>
                );
              })}
            </>
            {showDriverDetail && driverDetail && (
              <div className="absolute bottom-0 right-0 bg-white p-4 w-full">
                <div className="flex-1 flex items-center">
                  <DriverAvatar
                    driverName={driverDetail.title}
                    driverPhoto={driverDetail.image}
                    isActive={true}
                    className="cursor-pointer"
                  />
                  <div className="flex-1 grid ml-3">
                    <div className="flex items-center">
                      <span className="flex-initial text-slate-700 text-base font-medium">
                        {driverDetail.title}
                      </span>
                    </div>
                    <span className="text-gray-500 text-sm">
                      {driverDetail.subText}
                    </span>
                  </div>
                  <XMarkIcon
                    className="h-8 w-8 text-gray-400 cursor-pointer hover:bg-gray-100 rounded-full p-1"
                    onClick={() => setDriverDetail(null)}
                  />
                </div>
                <Button
                  className="mt-2"
                  size={"xs"}
                  onClick={() => {
                    setSelectedWidgetItem(driverDetail);
                    setIsAssignDriverModalOpen &&
                      setIsAssignDriverModalOpen(true);
                    if (isFullscreen) {
                      toggleFullscreen();
                    }
                  }}
                >
                  <span className="text-sm">Assign</span>
                </Button>
              </div>
            )}

            {routeData && (
              <>
                <Source id="route" type="geojson" data={routeData} />
                <Layer
                  id="route"
                  type="line"
                  source="route"
                  layout={{
                    "line-join": "round",
                    "line-cap": "round",
                  }}
                  paint={{
                    "line-color": "blue",
                    "line-width": 5,
                    "line-opacity": 0.5,
                  }}
                />
              </>
            )}
            <FullscreenControl position="top-right" />
          </MapGL>
        </div>
      </ErrorBoundary>
    </Loading>
  );
};

export default DriverMapWidget;
